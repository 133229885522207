import { Ref } from "vue";

export default function getKeysByValues<K, V>(
  map: Map<K, V>,
  searchValues: Ref<V[]>
): K[] {
  const keys: K[] = [];

  for (const [key, value] of map.entries()) {
    if (searchValues.value.includes(value)) {
      keys.push(key);
    }
  }

  return keys;
}
