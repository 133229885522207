<script setup lang="ts">
import { BrandApprovalApplicationResponse } from "@/api/brand-approval-application-service";
import {
  DefaultService as BrandApprovalService,
  ApiError as BrandApprovalServiceApiError,
  Decision,
  ProductSettingsGroup,
  ProductSettingsGroupsResponse,
} from "@/api/brand-approval-service";
import { ProductCountry } from "@/api/scion";
import { ProductDetailsForBackOffice } from "@/api/scion/models/ProductDetailsForBackOffice";
import { ProductDetailsService } from "@/api/scion/services/ProductDetailsService";
import { useT3Store } from "@/store";
import getKeysByValues from "@/utils/getKeyByValue";
import {
  LoadingSpinner,
  TwoButton,
  TwoCheckbox,
  TwoSearchField,
} from "@wegift/two-components";
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { STEP_FORM_01 } from "../../constants";
import countries_map from "../../utils/countriesMap";
import prioritizeCountryCodes from "../../utils/prioritizeCountryCodes";
const ACTIVE_PRODUCT_STATES = [
  "LIVE",
  "TEMPORARILY_DISABLED",
  "TEMPORARILY_DEGRADED",
];

const props = defineProps<{
  customerId: string;
  customerName: string;
  totalSteps: number;
  formData: BrandApprovalApplicationResponse | null;
  isConnect: boolean;
}>();

const handleKeyDown = (event: KeyboardEvent) => {
  if ((event.metaKey || event.ctrlKey) && event.key === "a") {
    event.preventDefault();
    selectedCountries.value = [...countries.value];
  }
};

onBeforeMount(() => {
  document.addEventListener("keydown", handleKeyDown);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeyDown);
});

const store = useT3Store();

const emit = defineEmits<{
  (e: "handleNextStep", currentStep: string): void;
  (e: "updateAnswersForm", data: any): void;
}>();

const countries = ref<string[]>([]);
const selectedCountries = ref<string[]>([]);
const searchQuery = ref("");
const isLoading = ref(true);

const toggleCountry = (country: string) => {
  const index = selectedCountries.value.indexOf(country);
  if (index > -1) {
    selectedCountries.value.splice(index, 1);
  } else {
    selectedCountries.value.push(country);
  }
};

const filteredCountries = computed(() => {
  const query = searchQuery.value.toLowerCase();
  return countries.value.filter((country) =>
    country.toLowerCase().includes(query)
  );
});

const allSelected = computed(() => {
  const visibleCountries = countries.value.filter((country) =>
    country.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
  return (
    visibleCountries.length > 0 &&
    visibleCountries.every((country) =>
      selectedCountries.value.includes(country)
    )
  );
});

const handleRemoveAll = () => {
  if (allSelected.value) {
    selectedCountries.value = selectedCountries.value.filter(
      (country) => !filteredCountries.value.includes(country)
    );
  } else {
    filteredCountries.value.forEach((country) => {
      if (!selectedCountries.value.includes(country)) {
        selectedCountries.value.push(country);
      }
    });
  }
};

//Get product list for which the customer has not applied yet
const filterAvailableProducts = (
  productDetailsList: ProductDetailsForBackOffice[],
  decisions: Decision[]
) => {
  return productDetailsList.filter(
    (productDetails: ProductDetailsForBackOffice) =>
      decisions.some(
        (decision: Decision) =>
          decision.product_code === productDetails.code &&
          decision.decision === "REQUIRES_CUSTOMER_ACTION"
      ) && ACTIVE_PRODUCT_STATES.includes(productDetails.state)
  );
};

const extractProductCodes = (data: ProductSettingsGroupsResponse) =>
  data.products?.map(
    (item: ProductSettingsGroup) => item.product_code
  ) as string[];

const logSelectedCountries = async () => {
  try {
    let filteredCountryCodes: string[] | null = null;
    const allProducts = await BrandApprovalService.getApiV1Rules();
    const productCodes = extractProductCodes(allProducts);

    // Get product details list
    const { products } =
      await ProductDetailsService.getProductDetailsForBackOffice({
        product_codes: productCodes,
      });

    //Get decisions for the customer - used to filter the products for which the user has not applied for
    const { decisions } = await BrandApprovalService.getApiV1Decision(
      props.customerId,
      undefined,
      "Tier 3,Tier 6,Tier 7"
    );
    if (decisions) {
      const filteredProducts = filterAvailableProducts(products, decisions);
      store.setAvailableProductsForCustomer(filteredProducts);
      //Extract the country codes of the products for which the user has not applied for
      filteredCountryCodes = Array.from(
        new Set(
          filteredProducts.flatMap(
            (filteredProduct: ProductDetailsForBackOffice) =>
              filteredProduct.countries.map(
                (country: ProductCountry) => country.code
              )
          )
        )
      );
    }

    const prioritized_country_codes = prioritizeCountryCodes(
      filteredCountryCodes || []
    );

    if (prioritized_country_codes != undefined) {
      for (let key of prioritized_country_codes) {
        let country_name = countries_map.get(key);
        if (country_name != undefined) {
          countries.value.push(country_name);
        }
      }
      isLoading.value = false;
    }
  } catch (ex) {
    if (ex instanceof BrandApprovalServiceApiError && ex.status === 404) {
      return null;
    }
    throw ex;
  }
};

const setCountryCodes = () => {
  const countryCodes = getKeysByValues(countries_map, selectedCountries);
  store.setSelectedCountryCodes(countryCodes);
};

const handleNext = () => {
  emit("handleNextStep", STEP_FORM_01);
};

const submitCountries = async () => {
  setCountryCodes();
  await logSelectedCountries();
  handleNext();
};

const formatCountryToId = (country: string) => country.split(" ").join("-");

onMounted(logSelectedCountries);
</script>
<template>
  <p
    class="font-heading text-xl font-extrabold leading-7"
    data-testid="form-title"
  >
    You can apply for merchants across {{ countries.length }} countries.
  </p>
  <p class="mb-6 text-base" data-testid="form-subtitle">
    To filter relevant merchants, please select the countries you operate in.<br />
    If you don’t see a country you are interested in underneath, it means you
    have applied for all merchants in that country right now.
  </p>
  <div class="grid grid-flow-col grid-rows-3 gap-4">
    <div class="row-span-3">
      <TwoSearchField
        data-testid="country-search-input-field"
        v-model="searchQuery"
        placeholder="Search"
      />

      <div
        class="mt-4 h-64 overflow-y-auto rounded border border-grey-200 bg-grey-50"
        :class="{
          'flex items-center justify-center':
            isLoading || countries.length === 0,
        }"
      >
        <div v-if="isLoading" class="flex items-center justify-center">
          <LoadingSpinner size="16" class="mr-2" />
          <div class="text-sm">Loading countries...</div>
        </div>
        <div
          v-if="countries.length === 0 && !isLoading"
          class="flex flex-col items-center justify-center"
        >
          <div
            class="leading-line-height-20px text-sm font-normal text-grey-800"
          >
            You’ve applied for all products
          </div>
          <div
            class="leading-line-height-20px mt-2 max-w-[174px] text-center text-xs font-light text-grey-800"
          >
            Check back regularly to apply for new opportunities
          </div>
        </div>
        <div
          :data-testid="`country-row-${formatCountryToId(country)}`"
          v-for="country in filteredCountries"
          :key="formatCountryToId(country)"
          @click="toggleCountry(country)"
          class="flex cursor-pointer flex-row px-4 py-2 text-sm"
          :class="{ 'bg-grey-100': selectedCountries.includes(country) }"
        >
          <div class="pointer-events-none">
            <TwoCheckbox
              :data-testid="`checkbox-country-${formatCountryToId(country)}`"
              class="checkbox"
              :modelValue="selectedCountries.includes(country)"
            />
          </div>
          {{ country }}
        </div>
      </div>
      <TwoButton
        class="btn-primary mt-4 w-full"
        data-testid="button-next"
        @click="submitCountries"
        :disabled="selectedCountries.length === 0 || isLoading"
      >
        Next
      </TwoButton>
    </div>
    <div class="col-span-2 row-span-2">
      <TwoButton
        data-testid="bulk-country-select"
        class="mt-2 flex w-32 flex-row content-center gap-2 align-middle"
        :class="{ 'btn-primary !p-3.5': allSelected, '': !allSelected }"
        @click="handleRemoveAll"
        :disabled="countries.length === 0"
      >
        <div class="text-[18px]">
          <font-awesome-icon
            v-if="allSelected"
            :icon="['fas', 'xmark']"
            style="color: #ffffff"
          />
          <font-awesome-icon
            v-else="allSelected"
            :icon="['fas', 'plus']"
            style="color: #000000"
          />
        </div>
        <div class="flex-4">
          {{ allSelected ? "Remove all" : "Select all" }}
        </div>
      </TwoButton>
    </div>
  </div>
</template>
