/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Decision } from "../models/Decision";
import type { DecisionBatchResponse } from "../models/DecisionBatchResponse";
import type { DecisionRequestBatch } from "../models/DecisionRequestBatch";
import type { DecisionStatusEnum } from "../models/DecisionStatusEnum";
import type { DetailedDecision } from "../models/DetailedDecision";
import type { MoreInformationRequestedCase } from "../models/MoreInformationRequestedCase";
import type { MoreInformationRequestedUpdateCase } from "../models/MoreInformationRequestedUpdateCase";
import type { ProductSettingsGroupsResponse } from "../models/ProductSettingsGroupsResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DefaultService {
  /**
   * Brand Approval Service API endpoint for getting decisions for given customer.
   * @param customerId Customer hashed id from Scion
   * @param productCodes Comma separated list of product codes you wish to see the decision information of
   * @param settingsGroupNames Comma separated list of setting group names to filter decisions by
   * @returns any List of products for customer with decisions
   * @throws ApiError
   */
  public static getApiV1Decision(
    customerId: string,
    productCodes?: string,
    settingsGroupNames?: string
  ): CancelablePromise<{
    decisions?: Array<Decision>;
  }> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/decision/{customer_id}",
      path: {
        customer_id: customerId,
      },
      query: {
        product_codes: productCodes,
        settings_group_names: settingsGroupNames,
      },
      errors: {
        400: `Bad input parameter`,
        401: `Unauthenticated`,
        404: `Customer not found`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for getting the history of decisions for a given customer and product code.
   * @param customerId Customer hashed id from Scion
   * @param productCode Product Code from Scion
   * @returns any List of decisions for customer and product
   * @throws ApiError
   */
  public static getApiV1Decision1(
    customerId: string,
    productCode: string
  ): CancelablePromise<{
    decisions?: Array<Decision>;
  }> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/decision/{customer_id}/{product_code}",
      path: {
        customer_id: customerId,
        product_code: productCode,
      },
      errors: {
        400: `Bad input parameter`,
        401: `Unauthenticated`,
        404: `Customer not found`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for getting decisions for a given decision status and list of product codes.
   * @param status Decision status you wish to query
   * @param productCodes Comma separated list of product codes you wish to see the decision information of
   * @param tier3 Should this query only return tier 3 decisions
   * @returns any List of product codes with decision data associated with the status and product code
   * @throws ApiError
   */
  public static getApiV1Decision2(
    status: DecisionStatusEnum,
    productCodes: string,
    tier3?: boolean
  ): CancelablePromise<{
    decisions?: Array<DetailedDecision>;
  }> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/decision",
      query: {
        status: status,
        product_codes: productCodes,
        tier3: tier3,
      },
      errors: {
        400: `Bad input parameter`,
        401: `Unauthenticated`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for manually overriding a batch of decisions of a customer and product list
   * @param requestBody
   * @returns DecisionBatchResponse OK
   * @returns any Accepted
   * @throws ApiError
   */
  public static postApiV1DecisionBatch(
    requestBody?: DecisionRequestBatch
  ): CancelablePromise<DecisionBatchResponse | any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/decision-batch",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthenticated`,
        404: `Customer not found`,
        500: `Internal Error`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for getting attachments for a given customer.
   * @param customerId Customer hashed id from Scion
   * @returns any List of attachment URLs associated with the customer
   * @throws ApiError
   */
  public static getApiV1Attachment(customerId: string): CancelablePromise<{
    attachments?: Array<string>;
  }> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/attachment/{customer_id}",
      path: {
        customer_id: customerId,
      },
      errors: {
        400: `Bad input parameter`,
        401: `Unauthenticated`,
        404: `Customer not found`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for accepting json requests containing rule responses from a user
   * @returns any OK
   * @throws ApiError
   */
  public static postApiV1Rules(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/rules",
      errors: {
        400: `Bad request`,
        401: `Unauthenticated`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint to get settings groups for all products in BAS
   * @returns ProductSettingsGroupsResponse OK
   * @throws ApiError
   */
  public static getApiV1Rules(): CancelablePromise<ProductSettingsGroupsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/rules",
      errors: {
        400: `Bad request`,
        401: `Unauthenticated`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for updating a more information requested case
   * @param customerId Customer ID of a more information requested case
   * @param caseReference Case reference of a more information requested case
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public static postApiV1MoreInformationRequested(
    customerId: string,
    caseReference: string,
    requestBody?: MoreInformationRequestedUpdateCase
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/more-information-requested/{customer_id}/{case_reference}",
      path: {
        customer_id: customerId,
        case_reference: caseReference,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthenticated`,
        404: `Case not found`,
      },
    });
  }

  /**
   * Brand Approval Service API endpoint for getting a 'more information requested' case by case number
   * @param customerId Customer ID of a more information requested case
   * @param caseReference Case reference of a more information requested case
   * @returns MoreInformationRequestedCase Data for a 'more information requested' case
   * @throws ApiError
   */
  public static getApiV1MoreInformationRequested(
    customerId: string,
    caseReference: string
  ): CancelablePromise<MoreInformationRequestedCase> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/more-information-requested/{customer_id}/{case_reference}",
      path: {
        customer_id: customerId,
        case_reference: caseReference,
      },
      errors: {
        400: `Bad input parameter`,
        401: `Unauthenticated`,
        404: `Case not found`,
      },
    });
  }
}
