export type CountryCode = string;

export default function prioritizeCountryCodes(
  countryCodes: CountryCode[]
): CountryCode[] {
  // Create an array with the priority country codes
  const priorityCountryCodes: CountryCode[] = ["US", "GB", "CA"];

  // Filter out the priority country codes from the original array
  const filteredCountryCodes: CountryCode[] = countryCodes.filter(
    (countryCode) => !priorityCountryCodes.includes(countryCode)
  );

  // Find the priority country codes in the original array and add them to the beginning of the filtered array
  priorityCountryCodes.forEach((priorityCountryCode) => {
    if (countryCodes.includes(priorityCountryCode)) {
      filteredCountryCodes.unshift(priorityCountryCode);
    }
  });

  return filteredCountryCodes;
}
